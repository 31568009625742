@import './mixins';

body {
  font-size: 1.11rem;
}

.clickable {
  cursor: pointer;
}

ul { @include reset(ul) };
a { @include reset(a) };

.color-red {
  color: $color-red;
}

.pad-wrap {
  @include pad-wrap;
}

.wrapper {
  width: 90%;
  @include center-box(x);
}

.ff-text { font-family: $ff-text; }
.ff-text-alt { font-family: $ff-text-alt; }

.btn,
[class|="btn"] {
  border: 1px solid white;
}
