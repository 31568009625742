@import './vars';

@mixin hmarg($val) {
  margin: {
    left: $val;
    right: $val;
  }
}

@mixin vpad($val) {
  padding: {
    top: $val;
    bottom: $val;
  }
}

@mixin hpad($val) {
  padding: {
    left: $val;
    right: $val;
  }
}

@mixin pad-wrap() {
  @include hpad(5%);
}

@mixin reset($tag) {
  @if ($tag == ul) {
    padding: 0px;
    margin: 0px;
    list-style: none;
  }

  @if ($tag == a) {
    color: inherit;
    text-decoration: none;
  }
}


@mixin flex($direction: row, $align: null, $justify: null) {
  display: flex;
  flex-direction: $direction;

  @if ($justify != null) {
    justify-content: $justify;
  }
  
  @if ($align != null) {
    align-items: $align;
  }
  
}

@mixin center-box($axis: xy) {
  @if ($axis == x) {
    @include hmarg(auto);
  }
  
  @if ($axis == y) {
    @include flex(column, $justify: center);
  }

  @if ($axis == xy) {
    @include flex(column, $justify: center);
    @include hmarg(auto);
  }
}
