@import '../../styles/common.scss';

@media screen and (max-width:768px){

        header.topbar  {
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0px 24px;
          margin-top: 10px;
          margin-bottom: 3em;

        }
        
        header.containerBtnsRight {
          display: flex;
          flex-direction: row;
          align-items: center;
        }



        header.topbar {
            .active {
              color: #cc4a4d;
              outline: none;
            }

            .account-button {
              margin-right: 24px;
            }

           #brand {
                display: flex;
                flex: none;
           }
           nav {
                
                font-size: 10px;
                float: none;
                text-align: center;
                line-height: 25px;
                border-radius: 4px;
                padding: 10px 12px;
           }

        }

    }

@media screen and (min-width:300px){
    header.topbar {
      display: flex;
      justify-content: space-evenly;

      #brand {
        color: white;
      }
      .account-button {
        @include center-box(y);
      }

      nav {
        transition-duration: 0.35s;
      }
    }
}

@media screen and (min-width:768px) and (max-width: 920px){
    nav {
      /*font-size: 12px;
      transition-duration: 0.35s;*/
    }
}

