@import '../../styles/common.scss';

#brand {
  color: white;
  @include flex(center, center);
  display: flex;
  justify-content: center;

  img.icon {
    display: block !important;
    width: 24px;
    margin-right: 8px;
  }

  i {
    display: block;
    font-style: normal;
    font-size: 23px;
    font-weight: bold;
    color: rgba(255, 176, 0, 1);
  text-shadow: 0px 0px 30px  rgba(255, 176, 0, 0.6);

  }
}

#logo__mask {
  backdrop-filter: blur(2px) saturate(3);
}
