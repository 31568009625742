@font-face {
  font-family: 'RuneScape UF';
  src: local('RuneScape UF'), url('./fonts/runescape_uf.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RuneScape Plain 11';
  src: local('RuneScape Plain 11'), url('./fonts/RuneScape-Plain-11.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RuneScape Plain 12';
  src: local('RuneScape Plain 12'), url('./fonts/RuneScape-Plain-12.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  height: -webkit-fill-available;
}

body {
  background: #060606;
  margin: 0;
  font-family: "RuneScape Plain 12", monotone;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/img/bricks.png');
}

::-webkit-scrollbar {
  background: #25201a;
  box-shadow: 10px 0px 5px 0px #26211d inset, -2px 0px 6px 0px #6e685c96 inset;
  border: 1.5px solid black;
}
 
::-webkit-scrollbar-thumb {
  background: #5c513b;
  box-shadow: 3px 3px 2px 0px #706551 inset, -1px -1px 5px 0px #080100 inset;
  border: 1.5px solid black;
}


@media only screen and (min-width: 320px) {
  ::-webkit-scrollbar {
    width: 12px;
  }
}

@media only screen and (min-width: 480px) {
  ::-webkit-scrollbar {
    width: 18px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    margin-right: calc(-1 * (100vw - 100%));
    overflow-x: hidden;
  }
}
