@import '../../../styles/common.scss';

@mixin _nav-item() {
  @include hpad(.7em);
  a { @include reset(a); }

  @content;
}

nav ul {
  @include flex(row, center, flex-end);

  > li {
    @include _nav-item() {
      &:hover { color: $color-green; }

      a.active {
        color: $color-red !important;
      }
    }
  }
}
